.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}

.App-header {
    min-height: 84vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #30394b;;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
