.react-calendar {
  width: 900px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__month-view__days__day--weekend {
	color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
}

.react-calendar__tile:disabled {
	background-color: rgba(255, 105, 105, 0.65);
	color: red;
	/* color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12); */

}

.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled {
  background: #fff;
	color: black;
	border:3px solid #1087ff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #fff;
	color: black;
	border: 3px solid #1087ff;
}

.react-calendar__tile {
 border: 3px solid red;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
	border: 3px solid transparent;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
}
/* -------------------------------------------------------- */
/* Remove weekends in month view */
.rbc-month-header > :nth-child(6),
.rbc-month-header > :nth-child(7){
  display: none;
}


.rbc-month-row > .rbc-row-bg > :nth-child(6),
.rbc-month-row > .rbc-row-bg > :nth-child(7) {
  display: none;
}

.rbc-row-content > .rbc-row > .rbc-date-cell:nth-child(6),
.rbc-row-content > .rbc-row > .rbc-date-cell:nth-child(7) {
  display:none;
}


